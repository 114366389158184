
import * as Yup from 'yup'

export const ProductSchema = Yup.object().shape({
      
      
  productName: Yup.string()
    .required('Name is required.'),
  description:Yup.string()
  .required("Description is required."),
  brand:Yup.string()
  .required("Brand is required.")  ,
  model:Yup.string()
  .required("Model is required.")  ,
  modelNumber:Yup.string()
  .required("Model Number is required.")  ,
  // sku:Yup.string()
  // .required("Sku is required."),
  regions: Yup.array()
  .min(1, "Minimum 1 region is Required")
  .max(1, "Maximum 1 region is Required")
  .required("region is required."),
  category: Yup.array()
  .min(1, "Minimum 1 Category is Required")
  .max(1, "Maximum 1 Category is Required")
  .required("Category is required."),
  subcategory: Yup.array()
  .min(1, "Minimum 1 subcategory is Required")
  .max(1, "Maximum 1 subcategory is Required")
  .required("Sub Category is Required"),
  /* productImages: Yup.array()
  .min(1, "Minimum 1 Accessory Image is Required")
  .required("Accessory Image is required."), */
  
});
      