import { useCallback, useEffect,useState } from "react";
import TableView from "../components/TableView";
import ProductTableAction from "../generatedTableActions/ProductTableAction";
import Button from "../components/Button";
import Badge from "../components/Badge";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/store";
import {
  setCurrentPage,
  setPerPage,
  setLoading,
  setProduct,
  setSearchTerm,
  setTotalPage
} from "../generatedSlice/productSlice";
import useSearchApi from "../customHooks/useSearchApi";
import { getProduct } from "../generatedRequest/product";
import { toast } from "react-toastify";
import TableSkeleton from "../components/TableSkeleton";
import ProductList from "../generatedTableList/ProductList";
import { TTableProps } from "../types/TableView";
import TableLoader from "../utils/Loaders/tableLoader";
import { useNavigate } from "react-router-dom";
import { TOptionType } from "../types/CustomSelect";
import ProductFilters from "../generatedFilters/ProductsFilters";
import { getRegion } from "../app/request/regionRequest";
import UploadProductFormModal from "../generatedFormModal/UploadProductFormModal";
import { ActionCells } from "../components/ActionCells";
import { toAbsoluteUrl } from "../_metronic/helpers";

const actions = ["Manually", "Upload-Excel"];

const ProductPage = () => {
  const dispatch = useDispatch();
  const [statusFilter, setStatusFilter] = useState<TOptionType<string>>({
    label: "",
    value: "",
  });
  const [regionFilter, setRegionFilter] = useState("");
  const [categoryFilter, setCategoryFilter] = useState("");
  const [subcategoryFilter, setSubCategoryFilter] = useState("");
  const [allNewRegion, setAllNewRegion] = useState([]);
  const [initload, setInitload] = useState(true);
  const [filterloading,setFilterLoading] = useState(false)
  const [uploadExcel, setUploadExcel] = useState(false);
  const {
    data,
    perPage,
    currentPage,
    totalPage,
    searchTerm,
    loading,
    sortBy,
    sortColumn,
    rerender1
  } = useSelector((state: RootState) => state.product);
  const columns = [
    {
      name: "image",
      renderHeader: (rows: any) => (
        <>
          <b>Image</b>
        </>
      ),
      renderCell: (row: any) => (
        <a
        href={
          // `${process.env.REACT_APP_API_URL_FOR_IMAGE}` +
          `${row && row.productImages && row.productImages[0]}`
        }
          // href={`/downloadtest?fileName=`+`${row &&  row.productImages &&  row.productImages[0]}`
            // `${process.env.REACT_APP_API_URL_FOR_IMAGE}` +
            // `${row && row.productImages && row.productImages[0]}`
          // }
          target="_blank"
          style={{height:"50px", width:"50px", display:"block",margin:"0 auto"}}
          // download={true}
        >
          <img src={
            // `${process.env.REACT_APP_API_URL_FOR_IMAGE}` +
            `${row &&  row.productImages &&  row.productImages[0]}`
          }
          style={{height:"100%", width:"100%", objectFit:"cover"}} 
          // height="50px"
          />
        </a>
      ),
    },
    {
      name: "name",
      renderHeader: (rows: any) => (
        <>
          <b>Name</b>
        </>
      ),
      renderCell: (row: any) => (
        <>
          {row && (
            <>
              {row.productName}
              {row.modelNumber && ` (${row.modelNumber})`}
            </>
          )}
        </>
      )
    },
  
    {
      name: "brand",
      renderHeader: (rows: any) => (
        <>
          <b>Brand</b>
        </>
      ),
      renderCell: (row: any) => <>{row && row.brand}</>,
    },
  
    {
      name: "status",
      renderHeader: (rows: any) => (
        <>
          <b>Status</b>
        </>
      ),
      renderCell: (row: any) => (
        <Badge
          variant={row.status == "active" ? "success" : "danger"}
          className="fs-7 fw-bolder"
        >
          {row.status}
        </Badge>
      )
    },
    {
      name: "subcategory",
      renderHeader: (rows: any) => (
        <>
          <b>Subcategory</b>
        </>
      ),
      renderCell: (row: any) => <>{row?.subcategory?.subcategoryName || row?.accessorySubcategory?.subcategoryName}</>,
    },
    {
      name: "regions",
      renderHeader: (rows: any) => (
        <>
          <b>Region</b>
        </>
      ),
      renderCell: (row: any) => (
        <>
          {row?.regions && Array.prototype.map.call(row.regions, function (item) {
            return (
              <span>
                <a
                  href={"/region/edit?q=" + item._id}
                  style={{ textDecoration: "underline" }}
                >
                  {item.regionName}
                </a>
                {"  "}
              </span>
            );
          })}
        </>
      ),
    },
    {
      name: "created_at",
      renderHeader: (rows: any) => (
        <>
          <b>Created At</b>
        </>
      ),
      renderCell: (row: any) => <>{row.createdAt}</>,
    },
    {
      name: "modified_at",
      renderHeader: (rows: any) => (
        <>
          <b>Modified At</b>
        </>
      ),
      renderCell: (row: any) => <>{row.updatedAt}</>,
    },
    {
      name: "Actions",
      renderHeader: (rows: any) => (
        <>
          <b>Actions</b>
        </>
      ),
      renderCell: (row: any) => <ProductTableAction regions={allNewRegion} product={row} />,
    },
  ];

  useEffect(() => {
    const fn = async () => {
      try {
        const response = await getRegion(`page=1&limit=100`);
        if (response.data.status) {
          setAllNewRegion(response.data.data.data);
        } else {
          toast.error("Something went wrong");
        }
      } catch (error) {
        toast.error("Network Error");
      } finally {
      }
    };
    fn();
  }, []);

  useEffect(() => {
    const fn = async () => {
      const searchQuery = `page=${currentPage}&limit=${perPage}&search=${searchTerm}&status=${statusFilter?.value}&region=${regionFilter}&category=${categoryFilter}&subcategory=${subcategoryFilter}`;
      dispatch(setLoading(true));
      try {
        const response = await getProduct(searchQuery);
        if (response.data.status) {
          dispatch(setProduct(response.data.data.data));
          dispatch(
            setTotalPage(Math.ceil(response.data.data.totalCount / perPage))
          );
        } else {
          toast.error("Something went wrong");
        }
      } catch (error) {
        toast.error("Network Error");
      } finally {
        dispatch(setLoading(false));
        setInitload(false);
        if(filterloading) {
          setFilterLoading(false)
        }
      }
    };
  fn();
  }, [rerender1,dispatch,statusFilter,regionFilter,categoryFilter,subcategoryFilter]);

  useEffect(() => {
    return () => {
      dispatch(setSearchTerm(''));
    };
  }, []);


  function handleAfterSearch(isSuceess: boolean, data: any, value: any) {
    if (isSuceess) {
      dispatch(setProduct(data.data.data.data));
      dispatch(setTotalPage(Math.ceil(data.data.data.totalCount / value)));
    }
  }
  const { doSearch, searchLoading } = useSearchApi(
    getProduct,
    handleAfterSearch
  );

  const handlePageChange = useCallback(
    (value: number) => {
      dispatch(setCurrentPage(value));
      const searchQuery = `page=${value}&limit=${perPage}&search=${searchTerm}&status=${statusFilter?.value}&region=${regionFilter}&category=${categoryFilter}&subcategory=${subcategoryFilter}`;
      doSearch(searchQuery, perPage);
    },
    [dispatch, perPage, searchTerm, doSearch]
  );

  const handlePerPageChange = useCallback(
    (value: number) => {
      dispatch(setPerPage(value));
      const searchQuery = `page=${currentPage}&limit=${value}&search=${searchTerm}&status=${statusFilter?.value}&region=${regionFilter}&category=${categoryFilter}&subcategory=${subcategoryFilter}`;
      doSearch(searchQuery, value);
    },
    [dispatch, currentPage, searchTerm, doSearch]
  );

  const handleSearchParams = useCallback(
    (value: string) => {
      dispatch(setCurrentPage(1));
      dispatch(setSearchTerm(value));
      const searchQuery = `page=${"1"}&limit=${perPage}&search=${value}&status=${statusFilter?.value}&region=${regionFilter}&category=${categoryFilter}&subcategory=${subcategoryFilter}`;
      doSearch(searchQuery, perPage);
    },
    [perPage, dispatch, doSearch]
  );

  const handleStatusFilter = useCallback(
    (data: TOptionType<string>) => {
      dispatch(setCurrentPage(1));
      setStatusFilter(data);
       setFilterLoading(true)
    },
    [perPage, dispatch, doSearch]
  );

  const handleRegionFilter = useCallback(
    (data: any) => {
      let payload = data.map((value: any) => value.id).join(",");
      dispatch(setCurrentPage(1));
      setRegionFilter(payload);
      setFilterLoading(true)
    },
    [perPage, dispatch, doSearch]
  );


  const handleCategoryFilter = useCallback(
    (data: any) => {
      let payload = data.map((value: any) => value._id).join(",");
      setCategoryFilter(payload);
      setFilterLoading(true)
    },
    [perPage, dispatch, doSearch]
  );
  const handleSubCategoryFilter = useCallback(
    (data: any) => {
      let payload = data.map((value: any) => value._id).join(",");
      dispatch(setCurrentPage(1));
      setSubCategoryFilter(payload);
      setFilterLoading(true)
    },
    [perPage, dispatch, doSearch,currentPage]
  );

  const configuration: TTableProps = {
    columns: columns,
    rows: data,
    showPagination: true,
    showSearch: true,
    showPerPage: true,
    paginationData: {
      perPage: perPage,
      currentPage: currentPage,
      totalPage: totalPage,
    },
    showFilter:true,
    filters:(<ProductFilters 
      onStatusFilterChange={handleStatusFilter}
      onRegionFilterChange={handleRegionFilter}
      currentRegionFilter={regionFilter}
      currentStatusFilter={statusFilter}
      onCategoryFilterChange={handleCategoryFilter}
      currentCategoryFilter={categoryFilter}
      onSubCategoryFilterChange={handleSubCategoryFilter}
      currentSubCategoryFilter={subcategoryFilter}
      showOnlyProductSubCategory={true}
    
    />),
    onPageChange: handlePageChange,
    onPerPageChange: handlePerPageChange,
    handleSearchParams: handleSearchParams,
    searchPlaceholder: "Search Products",
    searchLoading:searchLoading || filterloading,
  };

  const navigate = useNavigate();

  const handleUpload = useCallback(() => {
    setUploadExcel(!uploadExcel);
  }, [uploadExcel]);
  
  const handleSelect = (selectedAction: string) => {
    switch (selectedAction) {
      case "Manually":
        navigate("add");
        break;
      case "Upload-Excel":
        handleUpload();
        break;
      default:
        break;
    }
  };

  if (loading && initload) {
    return <TableSkeleton rows={10} columns={6} />;
  }



  return (
    <>
      <div className="d-flex justify-content-between align-items-center mb-5">
      <div className="d-flex align-items-center" style={{ gap: "5px" }}>
      <h2>Products </h2>
          <img
            alt="Logo"
            src={toAbsoluteUrl("/media/icons/duotune/art/exclamation-icon.svg")}
            className="h-20px cursor-pointer"
            onClick={()=>navigate(`/helps?moduleName=Product`)}

          />
      
        </div>
        {/* <Button
          variant="primary"
          size="medium"
          handleButtonClick={() => navigate("add")}
        >
          Add Product
        </Button> */}
           <ActionCells
          actions={actions}
          onSelect={handleSelect}
          title={"Add Product"}
          buttonStyle={`btn btn-light btn-primary btn-xl`}
          iconStyle={`svg-icon-4 ms-1`}
        />
      </div>
      <ProductList {...configuration}/>
      {uploadExcel && (
        <UploadProductFormModal onClose={() => handleUpload()} />
      )}
    </>
  );
};

export default ProductPage;
